import React from 'react';
import { Layout, SEO } from '../layout';
import {
	FadeInWhenVisibleLeft,
	FadeInWhenVisibleRight,
} from '../components/FadeInWhenVisible';

import 'swiper/css';
import 'swiper/css/navigation';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { motion } from 'framer-motion';
import WithTransition from '../components/WithTransition';
import { StaticImage } from 'gatsby-plugin-image';

SwiperCore.use([Navigation]);

const CarouselImages = () => {
	return (
		<>
			<Swiper
				pagination={{
					type: 'fraction',
				}}
				navigation={true}
				className="mySwiper">
				<SwiperSlide>
					{' '}
					<StaticImage
						src="../images/MulherP/IMG_0288-1.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<StaticImage
						src="../images/MulherP/site(3).jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<StaticImage
							src="../images/MulherP/site(2).jpeg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<StaticImage
						src="../images/MulherP/img4.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<StaticImage
						src="../images/MulherP/img5.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
			
			</Swiper>
		</>
	);
};

function PageJobMP() {
	const fadeLeft = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};
	return (
		<Layout>
			<SEO title="mulher pandêmica" />
			<section className="section-padding">
				<div className=" flow">
					<section className="container_page_jobs">
						<motion.h1
							className="title_page"
							variants={fadeLeft}
							initial="hidden"
							animate="visible"
							transition={{ duration: 1 }}>
							Mulher Pandêmica
						</motion.h1>
						<motion.img
							whileTap={{ scale: 0.9 }}
							drag={true}
							dragConstraints={{
								left: 0,
								right: 250,
								top: 0,
								bottom: 50,
							}}
							initial={{ opacity: 0, y: -100 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { duration: 1 },
							}}
							className="first_image"
							src="../images/MulherP/site.jpg"
						/>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									Mulher Pandêmica é um curta-metragem
									ficcional produzido em parceria com artistas
									multidisciplinares do estado de Alagoas,
									produzido em 2020 com direção coletiva e
									exibição na mostra oficial de cinema
									Alagoano “Mostra Sururu”.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<FadeInWhenVisibleLeft>
							<div className="container_text_job">
								<img
									className="second_image"
									src="../images/MulherP/img2.JPG"
								/>
							</div>
						</FadeInWhenVisibleLeft>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									A concepção do filme foi motivada pelo
									contexto de isolamento social e o desejo de
									localizar as implicações vividas por
									diversas mulheridades, onde é colocado em
									pauta as percepções, subjetividades,
									assombros e atualizações de cada mulher
									neste novo cenário.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<CarouselImages />
						<div className="infos">
							<p className="text_infos"></p>
							<img className="image_infos"></img>
						</div>
					</section>
				</div>
				<div className="section_infos">
					<section className="container_infos">
						<p className="text_bold">Ficha técnica:</p>
						<div className="texts_infos">
							<p className="text_bold">Direção e Roteiro:</p>
							<p className="text_indent">
								Luiza Leal, Maria França, Mirê Pi, Tayná
								Nogueira y Ayò Ribeiro
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Assistente de direção:</p>
							<p className="text_indent">Laís Lira</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Performers:</p>
							<p className="text_indent">
								Ayò Ribeiro, Maria França, Mirê Pi y Tayná
								Nogueira{' '}
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Narração:</p>
							<p className="text_indent">Ticiane Simões</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Direção de fotografia:</p>
							<p className="text_indent">Amanda Bambu</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Operação de câmera:</p>
							<p className="text_indent">
								Amanda Bambu y Luiza Leal
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Direção de arte:</p>
							<p className="text_indent">Didi Magalhães</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Assistente de arte:</p>
							<p className="text_indent">Ticiane Simões</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Direção de produção: </p>
							<p className="text_indent">Amanda Môa</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Assistência de produção:
							</p>
							<p className="text_indent">Ticiane Simões</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Som direto e desenho de som:
							</p>
							<p className="text_indent">Rodrigo Policarpo</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Montagem:</p>
							<p className="text_indent">Luiza Leal</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Assistente de montagem:</p>
							<p className="text_indent">Mirê Pi</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Logger:</p>
							<p className="text_indent">Isabela Camargo</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Design:</p>
							<p className="text_indent">
								Flávia Correia / Bombix Art Studio
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Motion:</p>
							<p className="text_indent">
								Joaddan Campos/Bagaceira Filmes
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Realização:</p>
							<p className="text_indent">
								Coletiva Corpatômica y Piracema Studio
							</p>
						</div>
					
						<br></br>

						<p className="text_bold">Apoio:</p>
						<p className="text_release">
							{' '}
							<p>Casa Sede</p>
							<p>Camila Couto</p>
							<p>Pizza Rock</p>
							<p>Cinepopeia</p> <p>Cia do
							Chapéu</p> 
							<p>Galpão 422</p>
							<p>Casa Mutum</p>
							<p>Bagaceira Filmes </p>
							<p>Meraki Lab</p>
							<p>Vinco Design</p>
							<p>Hilda Moura</p>
							
						</p>
					</section>
				</div>
			</section>
		</Layout>
	);
}

export default WithTransition(PageJobMP);
